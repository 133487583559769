module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


	for (var prop in data) {
		if (prop !== 'pagination') {
			var propObj = data[prop],
				photoObj = propObj.PHOTO,
				textObj = propObj.TEXT
					.replace(/[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi , function(link) {
						return `<a href="${link}" target="_blank" class="link link--internal">${link}</a>`
					})
					.replace(/#([^#\s]*)/gi , '<a href="https://twitter.com/hashtag/$1?src=hash" class="link link--internal" target="_blank">#$1</a>'),
				LANG = getLang();

			const moment = require('moment');
			moment.locale(LANG);
			var dateObj = moment(propObj.DATE).format('DD MMMM YYYY, HH:mm');
;
__p += '\n			<div class="social-network__item">\n				<span class="social-network__item-date">' +
((__t = (dateObj)) == null ? '' : __t) +
'</span>\n				<div class="social-network__item-content">\n					<p>\n						' +
((__t = (textObj)) == null ? '' : __t) +
'\n					</p>\n					<p><a href="' +
((__t = (propObj.LINK)) == null ? '' : __t) +
'" target="_blank" class="link link--internal">\n						';
 if (LANG == 'ru') { ;
__p += '\n							Подробнее\n						';
 } else if (LANG == 'en') { ;
__p += '\n							Read more\n						';
 } ;
__p += '\n					</a></p>\n				</div>\n			';
 if (!(photoObj == '' || photoObj == undefined)) { ;
__p += '\n				<a class="social-network__item-img" href="' +
((__t = (propObj.LINK)) == null ? '' : __t) +
'" target="_blank">\n					<img src="' +
((__t = (propObj.PHOTO)) == null ? '' : __t) +
'" alt="" class="one-whole">\n				</a>\n			';
 } ;
__p += '\n			</div>\n';

		}
	}
;
__p += '\n';

}
return __p
}