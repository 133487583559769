$(document).on('click', '.js-modal', function (e) {
	e.preventDefault();
	let $this = $(this),
		type = $this.data('type'),
		$content;

	if (type == 'inline') {
		$content = $(`[data-id="${$this.data('content')}"]`);

		$.colorbox({
			href: $content,
			inline: true,
			onLoad: function () {
				if ($content.find('select').length) {
					$('#colorbox').removeAttr('tabindex'); //remove tabindex before select2 init
				}
			}
		});
	}

});

// Stop browser scrolling
$(document)
	.bind('cbox_open', function () {
		$('body').css({overflow: 'hidden'});
	}).bind('cbox_closed', function () {
		$('body').css({overflow: 'auto'});
	});
// Stop browser scrolling
