var $tabs = $('.tabs'),
	$accordion = $('.accordion'),
	hashStack = [];

import { $esgContainer , openEsgItem } from '../esg/esg.js';

global.updateTabsAccordionsSelectors = function () {
	$tabs = $('.tabs');
	$accordion = $('.accordion');
};

global.getHash = function () {

	var data = {};
	data.hash = window.location.hash.split('!');
	data.href = data.hash[0];
	data.ePath = data.hash[1];
	data.name = data.href.substr(1);
	data.type = undefined;

	if ($tabs.find('.tabs__button[href="' + data.href + '"]').length) {
		data.type = 'tabs';
	} else if ($accordion.find('.accordion__button[href="' + data.href + '"]').length) {
		data.type = 'accordion';
	} else if ($('.js-products__nav').find('a[href="' + data.href + '"]').length) {
		data.type = 'products';
	}

	return data;
};

function checkHash(options) {
	var data = global.getHash();

	let $esg = $('.tabs__button[href="' + data.href + '"]').closest('.esg-item');
	if ($esg.length && !$esg.hasClass('esg-item--open')) {
		openEsgItem($esg);
	}
	openParents(data);

	if ($('.esg-item[data-id="' + data.name + '"]').length && $('.esg-item').find('.tabs')) {
		tabs();
	}

	if (data.type === 'tabs') {
		openTabs(data, options.scroll);
	}
	if (data.type === 'accordion') {
		openAccordion(data, options.scroll);
	}
	if (data.type === 'products') {
		tabs();
	}
}

function openParents(data) {
	var $parents = $('[href="' + data.href + '"]').parents('.tabs__article, .accordion__section');

	$($parents.get().reverse()).each(function() {
		if ($(this).hasClass('accordion__section')) {
			var name = $(this).find('.accordion__header .accordion__button').attr('href');
			openAccordion({href: name});
		} else if ($(this).hasClass('tabs__article')) {
			var name = $(this).data('tab');
			openTabs({href: '#' + name,	name: name});
		}
	})
}

function openTabs(data, scroll) {
	var $thisTabs = $tabs.find('a[href="' + data.href + '"]').closest('.tabs');

	$thisTabs
		.children('.tabs__button')
		.removeClass('is-expand')
		.closest('a[href="' + data.href + '"]')
		.addClass('is-expand')
		.closest('.tabs')
		.children('.tabs__article')
		.removeClass('is-expand')
		.closest('[data-tab="' + data.name + '"]')
		.addClass('is-expand');

	if (scroll) {
		setTimeout(function () {
			scrollToElement(data.href);
		}, 0);
	}
}

function openAccordion(data, scroll) {
	var $thisSection = $accordion.find('[href="' + data.href + '"]').closest('.accordion__section');

	if (!$accordion.find('[href="' + data.href + '"]').hasClass('is-expand')) {

		if (!hashStack.includes(data.href)) {
			hashStack.push(data.href);
		}
		$thisSection
			.closest('.accordion')
			.find('.accordion__button.is-expand')
			.removeClass('is-expand')
			.closest('.accordion__section')
			.children('.accordion__article')
			.velocity('slideUp', {
				duration: 600,
				easing: 'easeOutQuart',
				complete: function () {
					$(this).removeClass('is-expand');
				}
			});

		$thisSection
			.children('.accordion__header')
			.children('.accordion__button')
			.addClass('is-expand');
		$thisSection
			.children('.accordion__article')
			.velocity('slideDown', {
				duration: 600,
				easing: 'easeOutQuart',
				begin: function () {
					var $this = $(this),
						$year = $this.data('year');
					$this.addClass('is-expand');
					if ($this.hasClass('significant-facts') && !$this.hasClass('significant-facts-uploaded')) {
						$this.html('<div class="significant-facts__preloader u-align-center"><img src="/images/svg/loader.svg"></div>');
						$.post(location.href, {year: $year, AJAX_CALL: 'Y'}, function (response) {
							$this
								.addClass('significant-facts-uploaded')
								.html(response)
								.find('.significant-facts__preloader')
								.remove();
						});
					}
				}
			});
	}

	if (scroll) {
		setTimeout(function () {
			scrollToElement(data.href);
		}, 0);
	}
}

function closeAccordion(event) {

	event.preventDefault();

	var $this = $(this);

	hashStack.splice(hashStack.indexOf($this.attr('href')), 1);

	var hash = hashStack[hashStack.length - 1];

	window.location.hash = hash || '!';

	var $enclosedAccordion = $this.closest('.accordion__section');
	$enclosedAccordion
		.find('.accordion__button')
		.removeClass('is-expand');
	$enclosedAccordion
		.find('.accordion__article')
		.removeClass('is-expand')
		.velocity('slideUp');
}

function scrollToElement(href) {
	var topPos = $('[href="' + href + '"]').offset().top;
	$('html')
		.velocity('scroll', {
			offset: topPos + 'px',
			duration: 500,
			mobileHA: false
		});
}

function tabs() {
	$tabs.each(function (index, el) {

		var $this = $(el),
			$showTab = $this.attr('data-show-tab'),
			$href = ($showTab !== undefined && $tabs.find('.tabs__button[href="#' + $showTab + '"]').length) ? ('#' + $showTab) : $this.find('.tabs__button').eq(0).attr('href'),
			$name = $href.substr(1);

		// window.location.hash = $name;
		openTabs({
			href: $href,
			name: $name
		});
	});
}

function firstAccordionOpen() {
	let $accordionFirst = $('.accordion').eq(0);
	if ($accordionFirst.length && $accordionFirst[0].hasAttribute('data-initial-collapsed')) {
		return false;
	}
	$accordionFirst.each(function (index, el) {
		var $this = $(el),
			$showAcc = $this.attr('data-show-accordion'),
			$href = ($showAcc !== undefined && $accordionFirst.find('.accordion__button[href="#' + $showAcc + '"]').length) ? ('#' + $showAcc) : $this.find('.accordion__button').eq(0).attr('href'),
			$name = $href.substr(1);

		// window.location.hash = $name;
		openAccordion({
			href: $href,
			name: $name
		});
	});
}

$(window).on('load', function() {

	// if (!window.location.hash.length || !$('[href="' + window.location.hash + '"]').length) {
	if (!getHash().href.length) {
		firstAccordionOpen();
		tabs();
	} else {
		checkHash({scroll: true});
	}
	// } else {
	// }

	$(window).on('hashchange', checkHash);

	$(document).on('click', '.accordion__button.is-expand', closeAccordion);
});